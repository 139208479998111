@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

.good-font {
  font-family: "Space Grotesk", sans-serif;
}

.scroll-hidden::-webkit-scrollbar {
  display: none;
}

.scroll-bar-cool {
}

/* .scroll-bar-cool:before {
  content:'';
  width:100%;
  height:100%;    
  position:absolute;
  left:0;
  top:0;
  background:linear-gradient(transparent 150px, white);
} */

.scroll-bar-cool::-webkit-scrollbar {
  width: 10px !important;
  background-color: #f8f8f8 !important;
  border-radius: 12px !important;
}

.scroll-bar-cool::-webkit-scrollbar-thumb {
  background-color: #8ce4f9 !important;
  border-radius: 12px !important;
}

.scroll-bar-cool-1::-webkit-scrollbar {
  width: 3px !important;
  background-color: #f8f8f8 !important;
  border-radius: 12px !important;
}

.scroll-bar-cool-1::-webkit-scrollbar-thumb {
  background-color: #000 !important;
  border-radius: 12px !important;
}

.scroll-bar-cool-6::-webkit-scrollbar {
  width: 5px !important;
  background-color: #00000000 !important;
  border-radius: 12px !important;
}

.scroll-bar-cool-6::-webkit-scrollbar-thumb {
  background-color: #fff !important;
  border-radius: 12px !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.bg-got {
  background: rgb(81, 0, 162);
  background: linear-gradient(
    119deg,
    rgba(81, 0, 162, 1) 49%,
    rgba(0, 0, 0, 1) 100%
  );
}

.flip-card {
  background-color: transparent;
  width: 100%;
  height: 300px;
  perspective: 1000px;
  margin-bottom: 20px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.rota {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-front {
}

.flip-card-back {
  transform: rotateY(180deg);
}

.svg-gora {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(140deg)
    brightness(107%) contrast(101%) !important;
}

.svg-green {
  filter: invert(79%) sepia(6%) saturate(1699%) hue-rotate(91deg)
    brightness(88%) contrast(93%) !important;
}
